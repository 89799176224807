.container {
  position: fixed;
  inset: 0;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 9998;

  &.menu {
    top: calc(var(--snackbar-height) + var(--header-height));
  
    &.scrolled {
      top: calc(var(--header-offset-mobile) + var(--header-height));
    }

    & > :last-child {
      transition: all 300ms ease-in-out;
    }
  }
  @media(--large) {
    position: static;
  }
}

.hidden {
  pointer-events: none;
  @media(--large) {
    display: block;
  }

  &.menu {
    & > :last-child {
      transform: translate(-100%);
    }
  }
}

.mobile-overlay {
  position: absolute;
  opacity: 0;
  pointer-events: none;
  width: 100%;
  height: 100%;
  background-color: var(--color-primary-20);
  z-index: -1;
  backdrop-filter: blur(2px);
  transition: all 300ms ease-in-out;

  &.active {
    pointer-events: unset;
    opacity: 1;
  }

  @media(--large) {
    display: none;
  }

}
